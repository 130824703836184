import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SourceDocument } from '../types';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { TitleSection } from './Common';
import { MuiMarkDown } from './MuiMarkdown';

interface Props {
  sourceDocuments: SourceDocument[] | null;
}

const getName = (value: string) =>
  value
    .split('/')
    .slice(-1)[0]
    .replaceAll(/\.html|\.pdf|_|-/gi, ' ')
    .trim();

export const References: React.FC<Props> = ({ sourceDocuments }) => {
  if (!sourceDocuments?.length) {
    return null;
  }

  return (
    <TitleSection
      title="References:"
      icon={<LibraryBooksIcon fontSize="medium" />}
      contentPadding="32px"
    >
      <Accordion elevation={1}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {sourceDocuments.length} references available, expand for details.
        </AccordionSummary>
        <AccordionDetails>
          {sourceDocuments.map(({ page, page_content, source }, i) => {
            return (
              <Accordion
                elevation={0}
                key={i}
                sx={{
                  '& p, & strong': {
                    //use sx and select related class
                    fontSize: '14px', // change its style
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Stack
                    alignItems="center"
                    flex={1}
                    direction="row"
                    spacing={1}
                  >
                    <PictureAsPdfIcon fontSize="small" color="action" />
                    <Typography>{getName(source)}</Typography>
                    <Typography variant="overline">page {page}</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <MuiMarkDown mdContent={page_content} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </TitleSection>
  );
};
