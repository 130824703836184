import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  Chip,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';

import { conversationService } from '../api';
import { ListSkeleton } from './Skeletons';
import { NavLink } from 'react-router-dom';

export const ConversationMenu: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(true);

  const { conversationList, conversationIsLoading } = conversationService();

  const handleClick = () => {
    setOpen(!open);
  };

  const getSources = (chips: string[]) => `Sources: ${chips.join(', ')}`;

  return loggedIn ? (
    <List dense>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <ChatIcon />
        </ListItemIcon>
        <ListItemText primary="Conversations" />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {conversationIsLoading && <ListSkeleton contrast />}
        <List
          sx={{
            '.active': {
              bgcolor: '#dfdfdf',
            },
          }}
          component="div"
          disablePadding
        >
          {conversationList?.map(({ name, uid, chroma_db_filenames }) => (
            <ListItem
              component={NavLink}
              to={`/conversation/${uid}`}
              key={uid}
              disablePadding
              dense
            >
              <ListItemButton sx={{ pl: 3 }}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <ArtTrackIcon />
                </ListItemIcon>
                <Tooltip title={getSources(chroma_db_filenames)}>
                  <ListItemText
                    sx={{
                      minWidth: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    primary={name}
                  />
                </Tooltip>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  ) : null;
};
